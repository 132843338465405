import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.loading
    ? _c(
        "div",
        [
          _c(
            "div",
            { staticClass: "text-center mt-8" },
            [
              _c(
                VBtn,
                {
                  attrs: {
                    color: "primary",
                    rounded: "",
                    fab: "",
                    top: "",
                    right: "",
                    absolute: "",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.add_media_dialog()
                    },
                  },
                },
                [_c(VIcon, { attrs: { large: "" } }, [_vm._v("mdi-plus")])],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            [
              _c("SelectBriefingLanguageComponent", {
                on: {
                  handleChangeLanguage: function (e) {
                    return (_vm.selectedLanguage = e)
                  },
                },
              }),
            ],
            1
          ),
          _c(VSimpleTable, [
            _c("thead", [
              _c("tr", [
                _c("th", [_vm._v(_vm._s(_vm.$t("title")))]),
                _c("th", [_vm._v(_vm._s(_vm.$t("media_type")))]),
                _c("th", { staticClass: "text-center" }, [
                  _vm._v(_vm._s(_vm.$t("edit"))),
                ]),
                _c("th", { staticClass: "text-center" }, [
                  _vm._v(_vm._s(_vm.$t("remove"))),
                ]),
              ]),
            ]),
            _c(
              "tbody",
              _vm._l(
                _vm.media.filter(function (x) {
                  return _vm.gs.getLanguage(x.Language) === _vm.selectedLanguage
                }),
                function (item, index) {
                  return _c("tr", { key: index }, [
                    _c("td", [_vm._v(_vm._s(item.Title))]),
                    _c("td", [_vm._v(_vm._s(_vm.media_options[item.Type]))]),
                    _c(
                      "td",
                      { staticClass: "text-center" },
                      [
                        _c(
                          VBtn,
                          {
                            staticClass: "px-2 ml-1 secondary",
                            attrs: { "min-width": "0", small: "" },
                            on: {
                              click: function ($event) {
                                return _vm.edit_media_dialog(item)
                              },
                            },
                          },
                          [
                            _c(VIcon, { attrs: { small: "" } }, [
                              _vm._v("mdi-pencil"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "td",
                      { staticClass: "text-center" },
                      [
                        _c(
                          VBtn,
                          {
                            staticClass: "px-2 ml-1",
                            attrs: {
                              color: "red",
                              "min-width": "0",
                              small: "",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.open_delete_dialog(item)
                              },
                            },
                          },
                          [
                            _c(VIcon, { attrs: { small: "" } }, [
                              _vm._v("mdi-trash-can"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ])
                }
              ),
              0
            ),
          ]),
          _vm.dialog
            ? _c(
                VDialog,
                {
                  model: {
                    value: _vm.dialog,
                    callback: function ($$v) {
                      _vm.dialog = $$v
                    },
                    expression: "dialog",
                  },
                },
                [
                  _c(
                    VCard,
                    [
                      _c(VCardTitle, [
                        _c("h4", { staticClass: "dxa_modal_title h4" }, [
                          _vm._v(_vm._s(_vm.$t("manage_media"))),
                        ]),
                      ]),
                      _c(
                        VCardText,
                        [
                          _c(
                            VForm,
                            {
                              ref: "form",
                              staticClass: "mt-5",
                              on: {
                                submit: function ($event) {
                                  $event.stopPropagation()
                                  $event.preventDefault()
                                  return _vm.add_media()
                                },
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "mx-2" },
                                [
                                  _c(VSelect, {
                                    attrs: {
                                      items: _vm.index_options(
                                        _vm.media_obj.Type
                                      ),
                                      label: _vm.$t("index"),
                                      outlined: "",
                                    },
                                    model: {
                                      value: _vm.media_obj.Index,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.media_obj, "Index", $$v)
                                      },
                                      expression: "media_obj.Index",
                                    },
                                  }),
                                  _c(VTextField, {
                                    attrs: {
                                      rules: [_vm.required],
                                      label: _vm.$t("title"),
                                    },
                                    on: { keyup: _vm.isFieldsFilled },
                                    model: {
                                      value: _vm.media_obj.Title,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.media_obj, "Title", $$v)
                                      },
                                      expression: "media_obj.Title",
                                    },
                                  }),
                                  _c(VTextField, {
                                    attrs: {
                                      rules: [_vm.required],
                                      label: _vm.$t("video_id"),
                                      hint: _vm.$tc("vimeo_hint", 1),
                                    },
                                    on: { keyup: _vm.isFieldsFilled },
                                    model: {
                                      value: _vm.media_obj.VideoId,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.media_obj, "VideoId", $$v)
                                      },
                                      expression: "media_obj.VideoId",
                                    },
                                  }),
                                  _c(
                                    "div",
                                    { staticClass: "mt-2" },
                                    [
                                      _c("span", [
                                        _vm._v(_vm._s(_vm.$t("media_type"))),
                                      ]),
                                      _c(
                                        VRadioGroup,
                                        {
                                          attrs: {
                                            rules: [_vm.required],
                                            center: "",
                                            row: "",
                                            disabled: _vm.edit,
                                          },
                                          model: {
                                            value: _vm.media_obj.Type,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.media_obj,
                                                "Type",
                                                $$v
                                              )
                                            },
                                            expression: "media_obj.Type",
                                          },
                                        },
                                        [
                                          _c(VRadio, {
                                            attrs: {
                                              label: _vm.$t("leadership_chat"),
                                              value: 2,
                                            },
                                          }),
                                          _c(VRadio, {
                                            attrs: {
                                              label: _vm.$t("other"),
                                              value: 0,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "mt-2" },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.$t("media_plataform"))
                                        ),
                                      ]),
                                      _c(
                                        VRadioGroup,
                                        {
                                          attrs: {
                                            rules: [_vm.required],
                                            center: "",
                                            row: "",
                                          },
                                          on: { change: _vm.isFieldsFilled },
                                          model: {
                                            value: _vm.media_obj.Plataform,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.media_obj,
                                                "Plataform",
                                                $$v
                                              )
                                            },
                                            expression: "media_obj.Plataform",
                                          },
                                        },
                                        [
                                          _c(VRadio, {
                                            attrs: {
                                              label: _vm.$t("vimeo"),
                                              value:
                                                _vm.videoPlatformEnum.VIMEO,
                                            },
                                          }),
                                          _c(VRadio, {
                                            attrs: {
                                              label: _vm.$t("youtube"),
                                              value:
                                                _vm.videoPlatformEnum.YOUTUBE,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                VCardActions,
                                [
                                  _c(
                                    VBtn,
                                    {
                                      staticClass: "dxa_modal_btnSuccess",
                                      attrs: {
                                        color: "primary",
                                        disabled: _vm.disabled,
                                        "min-width": "100",
                                        loading: _vm.dialog_loading,
                                        type: "submit",
                                      },
                                    },
                                    [_vm._v(_vm._s(_vm.$t("save")))]
                                  ),
                                  _c(
                                    VBtn,
                                    {
                                      staticClass: "dxa_modal_btnError",
                                      attrs: {
                                        color: "red",
                                        "min-width": "100",
                                      },
                                      on: {
                                        click: function ($event) {
                                          _vm.dialog = false
                                        },
                                      },
                                    },
                                    [_vm._v(_vm._s(_vm.$t("cancel")))]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm.error
                            ? _c(
                                VAlert,
                                {
                                  staticClass: "ma-2",
                                  attrs: { type: "error" },
                                },
                                [_vm._v(" " + _vm._s(_vm.$t(_vm.error)) + " ")]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.delete_dialog
            ? _c(
                VDialog,
                {
                  model: {
                    value: _vm.delete_dialog,
                    callback: function ($$v) {
                      _vm.delete_dialog = $$v
                    },
                    expression: "delete_dialog",
                  },
                },
                [
                  _c("DeleteConfirmationModal", {
                    attrs: { obj: _vm.delete_obj, name: _vm.delete_obj.Title },
                    on: {
                      close: function ($event) {
                        _vm.delete_dialog = false
                      },
                      delete: _vm.remove_media,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      )
    : _c(
        VContainer,
        [
          _c(VProgressCircular, {
            staticStyle: { "margin-left": "50%" },
            attrs: { indeterminate: "", size: "70", color: "primary" },
          }),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }